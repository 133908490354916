import React from 'react'

import Gallery from '../../components/common/gallery';

const Paloquemao = () => (
  <Gallery
    imageLinks={
      [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32
      ].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/paloquemao/Paloquemao${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
  />
)

export default Paloquemao